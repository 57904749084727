import styled from "@emotion/styled";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { complement, isEmpty } from "ramda";
import React from "react";

import ContactHighlight from "../components/ContactHighlight";
import ContentWrapper from "../components/ContentWrapper";
import Heading from "../components/Heading";
import Hero from "../components/Hero/v7";
import Introduction from "../components/Introduction";
import Modular from "../components/Modular";
import SEO from "../components/SEO";
import theme from "../theme";
import { domainOverview } from "../types";

const notEmpty = complement(isEmpty);
const StyledHeading = styled(Heading)`
  max-width: ${theme.spacing("grid.6")};
`;
const StyledIntroduction = styled(Introduction)`
  max-width: ${theme.spacing("grid.6")};
  margin-bottom: ${theme.spacing("l")};
`;

export const DomainOverviewPage = ({
  data: {
    data: { domainOverview },
  },
}) => {
  const {
    title,
    description,
    heroImage,
    content,
    seoMetaTags,
    contactHighlight,
    tagline,
    descriptionHeading,
  } = domainOverview;

  return (
    <>
      <SEO tags={seoMetaTags} />
      <Hero
        title={title}
        image={heroImage}
        introduction={
          <Introduction color={"text.light"}>{tagline}</Introduction>
        }
      />

      <ContentWrapper columns={10}>
        {descriptionHeading && (
          <StyledHeading size={2}>{descriptionHeading}</StyledHeading>
        )}
        {description && (
          <StyledIntroduction html>{description}</StyledIntroduction>
        )}
      </ContentWrapper>

      {content.filter(notEmpty).map((item) => (
        <Modular key={item.id} {...item} type={item.__typename} />
      ))}
      {contactHighlight && <ContactHighlight {...contactHighlight} />}
    </>
  );
};

DomainOverviewPage.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      domainOverview: PropTypes.shape(domainOverview).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getDomains {
    data: dato {
      domainOverview {
        seoMetaTags: _seoMetaTags {
          tag
          content
          attributes
        }
        title
        tagline
        descriptionHeading
        description(markdown: true)
        heroImage {
          data: responsiveImage(
            imgixParams: { fit: crop, w: 960, h: 467, auto: format }
          ) {
            ...responsiveImageFragment
          }
        }
        content {
          ...basicTitleDescriptionFragment
          ...callToActionFragment
          ...imageFragment
          ...videoFragment
          ...quoteFragment
          ...textColumnFragment
          ...textImageFragment
          ...imageCarouselFragment
          ...featuredContentFragment
          ...casesHighlightFragment
        }
        contactHighlight {
          title
          description
          cta
          person {
            ...personFragment
          }
        }
      }
    }
  }
`;

export default DomainOverviewPage;
